import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

export function DefaultText({name, inputID, toggleErrorTxt, task, children, ...props})
{
    return (
        <div className='LoginContainer2'>
            <div className='LogintxtContainer'>
                <div className='Logintxt'>{name}</div>
                {toggleErrorTxt&&(<div className='LoginErrortxt'>(Cannot be empty)</div>)}
            </div>
            <input className='LoginInput' id={inputID} onKeyDown={task} maxLength={20}/>
        </div>
    )
}

export function DefaultTextCustomMSG({name, inputID, toggleErrorTxt, task, children, message, ...props})
{
    return (
        <div className='LoginContainer2'>
            <div className='LogintxtContainer'>
                <div className='Logintxt'>{name}</div>
                {toggleErrorTxt&&(<div className='LoginErrortxt'>{message}</div>)}
            </div>
            <input className='LoginInput' id={inputID} onKeyDown={task} maxLength={20}/>
        </div>
    )
}

export function PasswordText({name, inputID, toggleErrorTxt, showElement, setShowElement, task, children, ...props})
{
    return (
        <div className='LoginContainer2'>
            <div className='LogintxtContainer'>
                <div className='Logintxt'>{name}</div>
                {toggleErrorTxt&&(<div className='LoginErrortxt'>(Cannot be empty)</div>)}
            </div>
            <div className='passwordContainer'>
                <input type={showElement ? "password":"text"} className='LoginInput' id={inputID} onKeyDown={task}/>
                <div className="shpassword" onClick={() => setShowElement(!showElement)}>{showElement ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</div>
            </div>
        </div>
    )
}

export function PasswordTextCustomMSG({name, inputID, toggleErrorTxt, showElement, setShowElement, task, message, children, ...props})
{
    return (
        <div className='LoginContainer2'>
            <div className='LogintxtContainer'>
                <div className='Logintxt'>{name}</div>
                {toggleErrorTxt&&(<div className='LoginErrortxt'>{message}</div>)}
            </div>
            <div className='passwordContainer'>
                <input type={showElement ? "password":"text"} className='LoginInput' id={inputID} onKeyDown={task}/>
                <div className="shpassword" onClick={() => setShowElement(!showElement)}>{showElement ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</div>
            </div>
        </div>
    )
}